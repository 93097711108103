/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-cycle */
import axios from 'axios';
import G6 from '@antv/g6';
import exploreMap from '@/common/core/Graph/exploreGraphMap';
import exploreOps from '@/common/widgets/GraphExploreWidget/exploreOps';
import GraphEngine from '@/common/core/Graph/graphReactor/GraphEngine';
import graphReactor from '@/common/core/Graph/graphReactor';
import nodeHsl from '@/common/core/Graph/nodeHsl';
import { comboPlate } from '@/common/core/Graph/plate';
import addToThoughtMapUtil from '@/common/core/Graph/addToThoughtMapUtil';
import chroma from 'chroma-js';
import prompts from './prompts';

function nodeParseForLogicalData(data, query, state) {
  const nodeStyle = {
    lineWidth: 1,
    opacity: 0.3,
    stroke: '#fff',
    fill: state.colors[0] ? state.colors[0] : chroma.random().brighten().hex(),
  };
  state.colors.shift();
  console.log(state.colors);

  const expanded = {
    nodes: data.nodes.map((label) => ({
      id: label,
      label,
      tjType: 'openai_logic_node',
      logicQuestion: query,
      style: nodeStyle,
    })),
    edges: data.edges.map((edge) => ({
      source: data.nodes[edge[0]],
      target: data.nodes[edge[1]],
      label: edge[2],
      style: {
        endArrow: true,
      },
    })),
  };
  return expanded;
}
export default {
  toggleGroupMode({ commit }, booleanVal) {
    commit('SET_GROUP_MODE', booleanVal);
  },

  async groupNodeProcessWithOpenAI(
    { state, dispatch },
    {
      selectedNodes, query, nodeLabel, edgeLabel,
    },
  ) {
    const nodesToText = Array.from(selectedNodes).reduce((acc, curr) => {
      // eslint-disable-next-line no-param-reassign
      acc += `${curr.getModel().label}, `;
      return acc;
    }, '');
    console.log(nodesToText);
    const prompt = `Given these topics  ${
      state.anchor ? `(in context of ${state.anchor})` : ''
    }: \`${nodesToText}\` \n\n ${query}: `;
    const { data } = await axios.post(`${process.env.VUE_APP_AXIOS_URI}/eddie/query`, { prompt });
    console.log(data);
    exploreOps.nodeCreateSelect(
      {
        currentLabel: nodeLabel,
        currentEdgeLabel: edgeLabel,
        currentDescription: data.chatResponse,
      },
      selectedNodes,
      state.isArrowEdge,
    );
    dispatch('saveG6ExploreData');
    dispatch(
      'eventToEventStore',
      {
        verb: 'SUMMARIZE_NODES',
        value: {
          nodes: nodesToText,
          query,
        },
      },
      { root: true },
    );
  },

  // async createPropertiesWithOpenAI({ dispatch, state }, { selectedNodes, properties }) {
  // },

  async groupedPropertiesWithOpenAI({ dispatch, state }, { selectedNodes, properties }) {
    const nodesToText = Array.from(selectedNodes).reduce((acc, curr) => {
      // eslint-disable-next-line no-param-reassign
      acc += `${curr.getModel().label}, `;
      return acc;
    }, '');

    const propertiestTextPayload = properties.trim() ? properties.trim() : null;
    const prompt = prompts.propertiesPrompt(nodesToText, propertiestTextPayload, state.anchor);

    const { data } = await axios.post(`${process.env.VUE_APP_AXIOS_URI}/eddie/query`, { prompt });
    console.log(data);
    const propertiesData = JSON.parse(data.chatResponse); // { google: { instanceof: company }}
    const gloablType = {};
    selectedNodes.forEach((node) => {
      const currentNodeProperty = propertiesData[node.getModel().label];
      const sanitizedProperty = {};
      if (typeof currentNodeProperty === 'object') {
        Object.entries(currentNodeProperty).forEach(([key, value]) => {
          sanitizedProperty[key] = {
            value,
            type: 'custom',
          };
        });
      }

      gloablType[node.getModel().label] = sanitizedProperty;
    });

    console.log(gloablType);
    const nodesToTextSummarize = nodesToText;
    const propertiestTextPayloadSummarize = propertiestTextPayload;
    dispatch(
      'eventToEventStore',
      {
        verb: 'ADD_PROPERTIES',
        value: {
          title: nodesToTextSummarize,
          // eslint-disable-next-line
          properties: propertiestTextPayloadSummarize,
        },
      },
      { root: true },
    );

    selectedNodes.forEach((node) => {
      console.log('xvf props', node.getModel().props, gloablType[node.getModel().label]);
      node.update({
        props: { ...node.getModel().props, ...gloablType[node.getModel().label] },
      });
    });

    dispatch('saveG6ExploreData');
  },

  updateAnchor({ commit, state, dispatch }) {
    commit('SET_ANCHOR');
    dispatch(
      'eventToEventStore',
      {
        verb: 'ANCHOR_MARK',
        value: {
          title: state.active_node.label,
        },
      },
      { root: true },
    );
  },

  updateTextAsAnchor({ commit }, payload) {
    commit('SET_ANCHOR_FROM_TEXT', payload);
  },

  hiddenNodesList({ commit }, listOfNodes) {
    commit('ADD_HIDDEN_NODES', listOfNodes);
  },

  updateNewHiddenNodes({ commit }, listOfNodes) {
    commit('UPDATE_HIDDEN_NODES', listOfNodes);
  },

  async expandGraphDataUsingVector({ commit, state }, payload) {
    if (state.active_vector_store.memory_id.vector_store_id) {
      const graphData = await axios.post(
        `${process.env.VUE_APP_AXIOS_URI}/g6graph/smart/vector_generate`,
        {
          vs_id: state.active_vector_store.memory_id.vector_store_id,
          question: payload,
        },
      );
      graphData.data.combos = [];
      commit('EXPAND_DATA', graphData.data);
    }
  },

  async addLogicalData(
    {
      commit, dispatch, rootState, state,
    },
    {
      query, mode, selectedNodes, props,
    },
  ) {
    console.log('xvf', query, mode, selectedNodes, props);
    let graphData;
    try {
      // eslint-disable-next-line no-restricted-syntax
      for (const currQuery of query.split(',')) {
        // eslint-disable-next-line no-await-in-loop
        const { data } = await axios.post(`${process.env.VUE_APP_AXIOS_URI}/eddie/explore/graph`, {
          query: currQuery.trim(),
          mode,
          ...(Boolean(selectedNodes.length) && {
            selectedNodes: selectedNodes.map((node) => node.getModel()),
          }),
          ...(Boolean(state.anchor) && { anchor: state.anchor }),
        });
        graphData = nodeParseForLogicalData(data, query, rootState);
        graphReactor.exploreInstance.updateLayout();
        commit('EXPAND_DATA', { ...graphData, combos: [] });
        const querySummarize = query;
        dispatch(
          'eventToEventStore',
          {
            verb: 'LOGICAL_DATA',
            value: {
              query: querySummarize,
              // eslint-disable-next-line
              mode: mode,
            },
          },
          { root: true },
        );
      }
      return graphData;
    } catch (err) {
      console.log(err);
      return null;
    }
  },

  async formRelationshipUsingOpenAI({ state }, listOfNodes) {
    const sanitizedNodes = listOfNodes.map((node) => node.getModel());
    const { data } = await axios.post(`${process.env.VUE_APP_AXIOS_URI}/eddie/explore/graph`, {
      query: sanitizedNodes,
      mode: 'relationships',
      ...(Boolean(listOfNodes.length) && { selectedNodes: sanitizedNodes }),
      ...(Boolean(state.anchor) && { anchor: state.anchor }),
    });
    const graphData = nodeParseForLogicalData(data);
    console.log(graphData);
    graphData.nodes.forEach((node) => {
      // const exisitingEdge = graphReactor.exploreInstance.findAll('edge', (currEdge) => {
      //   const { source, target } = currEdge.get('model');
      //   return ((source === edge.source) && (target === edge.target));
      // });

      // console.log({ exisitingEdge });
      // const curveOffset = exisitingEdge[exisitingEdge.length - 1].getModel().curveOffset - 10;
      // const labelCfgY = exisitingEdge[exisitingEdge.length - 1].getModel().labelCfg.refY - 15;
      if (!graphReactor.exploreInstance.findById(node.id)) {
        graphReactor.exploreInstance.addItem('node', {
          ...node,
          // shape: 'arc',
          // curveOffset: curveOffset === 0 ? -10 : curveOffset,
          // labelCfg: {
          //   refY: labelCfgY,
          // },
        });
      }
    });
    graphData.edges.forEach((edge) => {
      // const exisitingEdge = graphReactor.exploreInstance.findAll('edge', (currEdge) => {
      //   const { source, target } = currEdge.get('model');
      //   return ((source === edge.source) && (target === edge.target));
      // });

      // console.log({ exisitingEdge });
      // const curveOffset = exisitingEdge[exisitingEdge.length - 1].getModel().curveOffset - 10;
      // const labelCfgY = exisitingEdge[exisitingEdge.length - 1].getModel().labelCfg.refY - 15;
      if (
        graphReactor.exploreInstance.findById(edge.source)
        && graphReactor.exploreInstance.findById(edge.target)
      ) {
        graphReactor.exploreInstance.addItem('edge', {
          ...edge,
          // shape: 'arc',
          // curveOffset: curveOffset === 0 ? -10 : curveOffset,
          // labelCfg: {
          //   refY: labelCfgY,
          // },
        });
      }
    });
    graphReactor.exploreInstance.updateLayout();
  },

  // eslint-disable-next-line no-unused-vars
  async pargraphicalData({ dispatch }, { query, mode, selectedNodes }) {
    const resultChunks = [];
    const totalWordCount = query.split(' ').length;
    const loop = totalWordCount / 200 + 1;
    console.log(loop);
    for (let i = 0; i < loop; i++) {
      const chunkedData = query.split(' ').slice(i * 200, (i + 1) * 200);
      console.log(chunkedData.length);
      resultChunks.push(chunkedData.join(' '));
    }

    const finalResult = resultChunks.map((i) => i.trim()).filter((j) => j !== '');
    console.log(finalResult);

    await Promise.all(
      finalResult.map((prompt) => dispatch('addLogicalData', {
        query: prompt,
        mode,
        selectedNodes,
      })),
    );
    const querySummarize = query;
    dispatch(
      'eventToEventStore',
      {
        verb: 'PARAGRAPHICAL_DATA',
        value: {
          query: querySummarize,
          // eslint-disable-next-line
          mode: mode,
        },
      },
      { root: true },
    );
  },

  async setCurrentGraph({ commit }, thoughtObject) {
    const response = await axios.get(
      `${process.env.VUE_APP_AXIOS_URI}/g6graph/${thoughtObject.graphId}`,
    );
    const fetchedData = response.data.g6_data.gdata;
    // console.log(thoughtObject);
    commit('SET_ACTIVE_GRAPH_DETAIL', {
      graphId: thoughtObject.graphId,
      memory_id: thoughtObject.memory_id,
      graphName: response.data.g6_data.graph_name,
      actor: thoughtObject.actor,
    });
    commit('SET_ACTIVE_GRAPH_ID', response.data, { root: true });
    if (thoughtObject.questLibrary) {
      commit('quests/SET_QUEST_GRAPH_DATA', fetchedData, { root: true });
    } else {
      commit('SET_G6_EXPLORE_DATA', fetchedData);
    }
  },

  async getGraphManifest(_, graphId) {
    const response = await axios.get(`${process.env.VUE_APP_AXIOS_URI}/g6graph/${graphId}`, {
      manifest: true,
    });
    return response.data;
  },

  async comboPropOperation({ dispatch }, { combosList, nodeList, fetchList }) {
    combosList.forEach((item) => {
      if (item) {
        const comboBoiler = comboPlate(item);
        graphReactor.exploreInstance.addItem('combo', comboBoiler);
      }
    });

    nodeList.forEach((nodeBoiler) => {
      const EdgeStore = [];

      const findNode = graphReactor.exploreInstance.find(
        'node',
        (node) => node.get('model').id === nodeBoiler.id,
      );

      if (findNode) {
        graphReactor.exploreInstance.updateItem(findNode, {
          comboId: nodeBoiler.comboId,
          ...(nodeBoiler.description.content && {
            description: {
              content: nodeBoiler.description.content,
            },
          }),
        });
      } else {
        graphReactor.exploreInstance.addItem('node', nodeBoiler);
      }

      EdgeStore.forEach((edge) => {
        graphReactor.exploreInstance.addItem('edge', edge);
      });

      graphReactor.exploreInstance.updateLayout();
    });

    fetchList.forEach((item) => {
      dispatch('exploreGraphFetch', item.trim());
    });

    await dispatch('saveG6ExploreData');
  },

  async deleteGraph({ commit, dispatch }, payload) {
    try {
      const response = await axios.delete(`${process.env.VUE_APP_AXIOS_URI}/g6graph/${payload.id}`);
      commit('SET_GRAPH_DELETED_DATA', response.data);
      await dispatch('deleteItemFromGraphLibrary', payload.id, { root: true });
      dispatch(
        'eventToEventStore',
        {
          verb: 'DELETED_GRAPH',
          value: {
            title: payload.g6_data.graph_name,
            graphId: payload.id,
          },
        },
        { root: true },
      );
      const values = {
        verb: 'DELETED_GRAPH',
      };
      dispatch('actionFeedback', values, { root: true });
      // if(response.status ==)
      return true;
    } catch (err) {
      return false;
    }
  },

  async userListSearchUpdate({ commit }, searchWord) {
    // http://localhost:7000/api/v1/userProfiles/user/search?userSearch=k
    const response = await axios.get(`${process.env.VUE_APP_AXIOS_URI}/userProfiles/user/search`, {
      params: { userSearch: searchWord },
    });
    commit('UPDATE_USER_LIST', response.data);
  },

  async quickUpdateGraphData({ rootState, state }) {
    try {
      const response = await axios.put(
        `${process.env.VUE_APP_AXIOS_URI}/g6graph/${rootState.active_graph_id.explore_library}`,
        {
          g6_data: {
            gdata: state.g6_explore_data,
          },
          metadata: {
            anchor: state.anchor,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          },
        },
      );

      return response;
    } catch (err) {
      console.log(err);
    }
    return null;
  },

  async updateGraph({ dispatch, state }, payload) {
    try {
      const isUpdate = payload.graph_access === 'private';
      const response = await axios.put(
        `${process.env.VUE_APP_AXIOS_URI}/g6graph/${payload.id}`,
        {
          g6_data: {
            gdata: payload.gdata,
            graph_name: payload.graph_name,
            graph_type: payload.graph_type,
          },
          metadata: {
            anchor: state.anchor,
          },
          tags: payload.graph_tags,
          access: payload.graph_access,
          ...(payload.graph_access === 'custom' && {
            sharedAccess: payload.sharedAccess,
          }),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          },
        },
      );

      dispatch('updateGraphLibrary', response.data, { root: true });

      dispatch('updateTextAsAnchor', state.anchor);
      const values = { verb: 'UPDATED_GRAPH' };
      dispatch('actionFeedback', values, { root: true });
      // commit('SET_GRAPH_UPDATED_DATA', response.data);
      dispatch(
        'eventToEventStore',
        {
          verb: 'UPDATED_GRAPH',
          value: {
            title: response.data.g6_data.graph_name,
            // eslint-disable-next-line
            graphId: response.data._id,
          },
        },
        { root: true },
      );

      const sendResponse = {
        _id: response.data.id,
        id: response.data.id,
        g6_data: {
          graph_name: response.data.g6_data.graph_name,
          graph_type: response.data.g6_data.graph_type,
        },
        timestamp: response.data.timestamp,
        actor: response.data.actor,
        tags: response.data.tags,
        access: response.data.access,
        graph_dimension: response.data.graph_dimension,
        sharedAccess: response.data.sharedAccess,
        metadata: response.data.metadata,
      };

      return { data: sendResponse, isUpdate };
    } catch (err) {
      // console.error(err);
      return null;
    }
  },

  async exploreGraphUnResponded({ commit }, exploreEntity) {
    const exploreResponseObj = await axios.post(`${process.env.VUE_APP_AXIOS_URI}/explore`, {
      query: exploreEntity,
    });
    const exploreMapResponse = await exploreMap(exploreResponseObj.data.links);
    // commit('pushMyNode', exploreEntity);
    const payload = { exploreMapResponse, exploreEntity };
    commit('setExploreGraphExpansion', payload);
  },

  async csvDataExpand({ commit }, data) {
    commit('EXPAND_DATA', data);
  },

  async exploreGraphFetch({ dispatch, commit, rootState }, exploreEntity) {
    const exploreResponseObj = await axios.post(`${process.env.VUE_APP_AXIOS_URI}/explore/links`, {
      query: exploreEntity,
      limit: rootState.explore_data_range,
    });

    // combo fix
    if (graphReactor.exploreInstance) {
      await dispatch('saveG6ExploreData');
    }
    const exploreMapResponse = await exploreMap(exploreResponseObj.data.links);
    commit('pushMyNode', exploreEntity);
    const payload = { exploreMapResponse, exploreEntity };

    commit('setExploreGraphExpansion', payload);
    // dispatch('saveG6ExploreData');
  },

  async updateLabelFromData({ commit, state }, payload) {
    const g6NodeData = state.g6_explore_data.nodes;
    const myNode = payload.node;
    myNode.label = payload.label;
    g6NodeData[g6NodeData.findIndex((el) => el.id === payload.node.id)] = myNode;
    const finalData = {
      nodes: g6NodeData,
      edges: state.g6_explore_data.edges,
      combos: state.g6_explore_data.combos,
    };
    commit('SET_G6_EXPLORE_DATA', finalData);
  },

  async deleteNodeFromData({ commit, state }, data) {
    const finalNodes = state.g6_explore_data.nodes.filter((i) => i.id !== data.id);
    const finalData = {
      nodes: finalNodes,
      edges: state.g6_explore_data.edges,
      combos: state.g6_explore_data.combos,
    };
    commit('SET_G6_EXPLORE_DATA', finalData);
  },

  async addToThoughtMap({ commit, state, rootState }, payload) {
    const currentWord = rootState.current_selected_entity.word || 'No selected entity';
    const currentGraphData = state.g6_explore_data;
    const userName = rootState.auth.user.username;

    const newData = addToThoughtMapUtil({
      currentWord,
      currentGraphData,
      userName,
      payload,
    });

    commit('SET_G6_EXPLORE_DATA', newData);
  },

  async saveG6ExploreData({ commit }) {
    const data = graphReactor.exploreInstance.save();

    commit('SET_G6_EXPLORE_DATA', data);
  },

  async setExploreData({ commit }, payload) {
    commit('SET_G6_EXPLORE_DATA', payload);
  },

  async exploreThoughtMap({ commit, state }, payload) {
    const currentGraphData = state.g6_explore_data;
    const response = await axios.get(`${process.env.VUE_APP_AXIOS_URI}/g6graph/${payload.graphId}`);

    const exploreGraphData = response.data.g6_data.gdata;
    const exploredNodes = exploreGraphData.nodes.map((el) => {
      const newEl = el;
      newEl.comboId = `${payload.graphId}`;
      return newEl;
    });
    const exploredEdges = exploreGraphData.edges;

    // const combo = {
    //   id: `${payload.graphId}`,
    //   ebel: payload.graphName,
    // }

    currentGraphData.nodes = [...currentGraphData.nodes, ...exploredNodes];

    const uniqueNodes = currentGraphData.nodes.reduce((uniqueMap, node) => {
      if (uniqueMap[node.id] === undefined) {
        // eslint-disable-next-line no-param-reassign
        uniqueMap[node.id] = node;
      }
      return uniqueMap;
    }, {});

    currentGraphData.nodes = Object.values(uniqueNodes);
    currentGraphData.edges = [...currentGraphData.edges, ...exploredEdges];
    if (exploreGraphData.combos === undefined) {
      currentGraphData.combos = [...currentGraphData.combos];
    } else {
      currentGraphData.combos = [...currentGraphData.combos, ...exploreGraphData.combos];
    }

    commit('SET_G6_EXPLORE_DATA', currentGraphData);
  },

  async dimensionDataFetch({
    rootState, commit, getters, state,
  }, payloadType) {
    const dimensionResponse = await axios.post(`${process.env.VUE_APP_AXIOS_URI}/dimension`, {
      nodeList: getters.getDimensionArray,
      pList: state.pList[payloadType],
      caseType: payloadType,
      lang: rootState.locale.currentLocale,
    });
    const dimensionData = dimensionResponse.data;

    commit('SET_DIMENSION_DATA', dimensionData);
  },

  async clearStates({ state }, opacityFlag = true) {
    graphReactor.exploreInstance.getNodes().forEach((node) => {
      graphReactor.exploreInstance.clearItemStates(node, [
        ...state.globalStates.nodes,
        'relatedEdIndex',
      ]);
      if (opacityFlag) {
        exploreOps.setOpacityLevel(node, 1);
      }
    });
    graphReactor.exploreInstance.getEdges().forEach((edge) => {
      graphReactor.exploreInstance.clearItemStates(edge, [
        ...state.globalStates.edges,
        'relatedEdIndex',
      ]);
      if (opacityFlag) {
        exploreOps.setOpacityLevel(edge, 1);
      }
    });

    graphReactor.exploreInstance.getCombos().forEach((combo) => {
      graphReactor.exploreInstance.clearItemStates(combo);
    });
  },

  async clearStatesByGraphInstance({ state }, payload) {
    const { opacityFlag } = payload;
    const { graphInstance } = payload;
    graphInstance.getNodes().forEach((node) => {
      graphInstance.clearItemStates(node, state.globalStates.nodes);
      if (opacityFlag) {
        exploreOps.setOpacityLevelByInstance(node, 1, graphInstance);
      }
    });
    graphInstance.getEdges().forEach((edge) => {
      graphInstance.clearItemStates(edge, state.globalStates.edges);
      if (opacityFlag) {
        exploreOps.setOpacityLevelByInstance(edge, 1, graphInstance);
      }
    });

    graphInstance.getCombos().forEach((combo) => {
      graphInstance.clearItemStates(combo);
    });
  },

  /* * * GraphExploreNode */

  // async nodeClickEvent({ state, rootState, dispatch, commit }, { graphName, event }) {
  //   const nodeItem = exploreOps.getItem(event);
  //   const nodeModel = nodeItem.getModel();
  //   await dispatch('clearStates');

  //   switch (state.edit_mode) {
  //     case 'explore':

  //   }
  // },

  async setActiveVectorStore({ commit }, graphDetail) {
    commit('SET_ACTIVE_VECTOR_STORE', graphDetail);
  },

  async graphNodeClick({
    state, rootState, dispatch, commit,
  }, { graphName, event }) {
    const nodeItem = exploreOps.getItem(event);
    const nodeModel = nodeItem.getModel();
    // console.log(event);

    if (state.edit_mode === 'explore') {
      await dispatch('clearStates');

      let nodeId = null;
      // eslint-disable-next-line
      const { id, tjType, label } = nodeModel;
      if (tjType === 'wikiLinks' || tjType === 'openai_logic_node') {
        nodeId = id;
      } else {
        nodeId = label;
      }

      const wordObj = {
        word: nodeId,
        label: nodeId,
        type: 'string',
        lang: rootState.locale.currentLocale,
      };

      // rootState.commit('SET_CURRENT_ENTITY', wordObj);
      await dispatch('setCurrentThought', { thoughtObject: wordObj }, { root: true });
      // eslint-disable-next-line
      exploreOps.importDataIntoGraph(state.g6_explore_data); // ? better way to do this?
      exploreOps.graphRender();

      const updatedNode = GraphEngine.getVertexValue('explore', nodeId);

      exploreOps.focusItem(updatedNode, 'node');

      // dispatch('eventToEventStore', {
      //   verb: 'EXPLORED',
      //   value: {
      //     title: nodeId,
      //     enload: nodeModel,
      //   },
      // }, { root: true });
      // axios.post(`${process.env.VUE_APP_ZELDA_URI}event`, {
      //   actor: rootState.userInfo,
      //   verb: 'GRAPHED',
      //   value: {
      //     data: graphReactor.exploreInstance.save(),
      //   },
      //   dataSource: 'TJ-CLIENT',
      // });
    } else if (state.edit_mode === 'remove') {
      GraphEngine.removeVertex(graphName, nodeItem);
    } else if (state.edit_mode === 'hide') {
      nodeItem.hide();
    } else if (state.edit_mode === 'lock') {
      const hasLocked = nodeItem.hasState('lockedState');
      /**
       * previousState is a backup state of node before node is Locked
       * */
      if (hasLocked) {
        /**
         * if node is locked
         * after unlock replace currentState with previous State
         * */
        const prevModel = nodeItem.getModel();
        if (prevModel.previousState) {
          exploreOps.updateMyNode(prevModel.previousState);
        }
        GraphEngine.setVertexValue(graphName, { item: nodeItem, state: 'lockedState' }, '-s-cs');
        // graphReactor.exploreInstance.clearItemStates(nodeItem, 'lockedState');
        nodeItem.unlock();
      } else {
        /**
         * if node is not locked
         * set currentState into previousState and update new State
         * */
        // graphReactor.exploreInstance.setItemState(nodeItem, 'lockedState', true);
        GraphEngine.setVertexValue(
          graphName,
          { item: nodeItem, state: 'lockedState', value: true },
          '-s',
        );
        nodeItem.lock();
        const myModel = nodeItem.getModel();
        exploreOps.updateMyNode({ ...myModel, previousState: { ...myModel }, size: '30' });
      }
    } else if (state.edit_mode === 'shortest_path') {
      // graphReactor.exploreInstance.setMode('shortest_path');
      setTimeout(() => {
        // const selectedNodes = graphReactor.exploreInstance.findAllByState(
        //   'node',
        //   'selected',
        // );
        const selectedNodes = GraphEngine.getVertexValue(
          graphName,
          { state: 'selected' },
          '-bystate',
        );
        // console.log('GraphEgine: selectedState', selectedNodes);
        if (selectedNodes.length === 2) {
          const { findShortestPath } = G6.Algorithm;
          // eslint-disable-next-line
          const { path, allPath } = findShortestPath(
            rootState.gmodule.g6_explore_data,
            selectedNodes[0].getID(),
            selectedNodes[1].getID(),
          );
          const pathNodeMap = {};
          const pathEdgeMap = {};
          path.forEach((id) => {
            // const pathNode = graphReactor.exploreInstance.findById(id);
            const pathNode = GraphEngine.getVertexValue(graphName, id);
            pathNode.toFront();
            GraphEngine.setVertexValue(
              graphName,
              {
                item: pathNode,
                state: 'spNode',
                value: true,
              },
              '-s',
            );

            // if (index !== (path.length - 1)) {
            //   const edgeNode = GraphEngine.getEdgeValue(graphName, [path[index], path[index + 1]]);
            //   console.log(edgeNode);
            // }
            // graphReactor.exploreInstance.setItemState(
            //   pathNode,
            //   'highlight',
            //   true,
            // );
            pathNodeMap[id] = true;
          });
          GraphEngine.getEdgeValue(graphName, {}, '-all').forEach((edge) => {
            const edgeModel = edge.getModel();
            const { source } = edgeModel;
            const { target } = edgeModel;
            const sourceInPathIdx = path.indexOf(source);
            const targetInPathIdx = path.indexOf(target);
            if (sourceInPathIdx === -1 || targetInPathIdx === -1) return;
            if (Math.abs(sourceInPathIdx - targetInPathIdx) === 1) {
              // graphReactor.exploreInstance.setItemState(
              //   edge,
              //   'highlight',
              //   true,
              // );
              // eslint-disable-next-line
              pathEdgeMap[edge._cfg.id] = true;
              GraphEngine.setEdgeValue(
                graphName,
                {
                  item: edge,
                  state: 'spEdge',
                  value: true,
                },
                '-s',
              );
            } else {
              // graphReactor.exploreInstance.setItemState(
              //   edge,
              //   'inactive',
              //   true,
              // );
              // GraphEngine.setVertexValue(graphName, {
              //   item: edge, state: 'opacityO', value: true,
              // }, '-s');
            }
          });
          GraphEngine.getVertexValue(graphName, {}, '-all').forEach((node) => {
            if (!pathNodeMap[node.getID()]) {
              // graphReactor.exploreInstance.setItemState(
              //   node,
              //   'inactive',
              //   true,
              // );
              GraphEngine.setVertexValue(
                graphName,
                {
                  item: node,
                  state: 'opacityO',
                  value: true,
                },
                '-s',
              );
              GraphEngine.setVertexValue(
                graphName,
                [
                  {
                    item: node,
                    value: {
                      labelCfg: {
                        style: {
                          opacity: 0.1,
                        },
                      },
                    },
                  },
                ],
                '-u',
              );
            }
          });
          GraphEngine.getEdgeValue(graphName, {}, '-all').forEach((edge) => {
            if (!pathEdgeMap[edge.getID()]) {
              // graphReactor.exploreInstance.setItemState(
              //   edge,
              //   'inactive',
              //   true,
              // );
              GraphEngine.setVertexValue(
                graphName,
                {
                  item: edge,
                  state: 'opacityO',
                  value: true,
                },
                '-s',
              );
            }
          });
        }
      }, 1000);
    } else if (state.edit_mode === 'default') {
      await dispatch('clearStates');
      // eslint-disable-next-line no-underscore-dangle
      const nodeLabel = nodeItem._cfg.id;
      const wordObj = {
        word: nodeLabel,
        label: nodeLabel,
        type: 'string',
        lang: 'en',
      };

      dispatch(
        'setCurrentThought',
        {
          thoughtObject: wordObj,
          excludeCallList: ['gmodule/exploreGraphFetch'],
        },
        { root: true },
      );
    } else if (state.edit_mode === 'hide_related_nodes') {
      const currentNodeInfo = nodeItem;
      const validNodes = currentNodeInfo.getNeighbors().filter((i) => i.getNeighbors().length <= 1);
      [...validNodes].forEach((node) => {
        graphReactor.exploreInstance.hideItem(node);
      });
      dispatch('hiddenNodesList', [...validNodes]);
    } else if (state.edit_mode === 'looked_at') {
      // eslint-disable-next-line
      // eslint-disable-next-line
      const { model } = nodeItem._cfg;
      const wordObj = {
        word: model.label,
        label: model.label,
        type: 'string',
        tjModel: model,
        lang: 'en',
      };

      await dispatch('clearStates');
      nodeHsl(nodeModel, 'explore', dispatch);
      dispatch(
        'setCurrentThought',
        {
          thoughtObject: {
            word: nodeModel.label,
            label: nodeModel.label,
            type: 'string',
            lang: 'en',
          },
          excludeCallList: ['gmodule/exploreGraphFetch'],
        },
        { root: true },
      );
      dispatch(
        'setCurrentThought',
        {
          thoughtObject: wordObj,
          excludeCallList: ['gmodule/exploreGraphFetch', 'quests/questGraphAction'],
        },
        { root: true },
      );

      commit('SET_ACTIVE_NODE', nodeItem.getModel());

      this.content = nodeItem.getModel();
      this.formDataObj = model;
      const payload = {
        content: this.content,
        formDataObj: this.formDataObj,
      };

      // search engine keyword fetch trigger
      dispatch('searchEngine/setKeyword', payload.content.label, { root: true });
      if (
        payload.content.tjType === 'wikiLinks'
        || payload.content.tjType === 'openai_logic_node'
      ) {
        GraphEngine.setVertexValue(
          graphName,
          { item: payload.content.id, state: 'focusEd', value: true },
          '-s',
        );
      }

      // setTimeout(() => {
      //   if (payload.content.tjType === 'wikiLinks') {
      //     // const nodeItemNodeOperation = graphReactor.exploreInstance.findById(
      //     //   payload.content.id,
      //     // );
      //     // const nodeItemNodeOperation = GraphEngine.getVertex(payload.content.id, graphName);
      //     // GraphEngine.setVertexValue(graphName, { item: payload.content.id, state: 'focusEd', value: true }, '-s');
      //     // graphReactor.exploreInstance.setItemState(
      //     //   nodeItemNodeOperation,
      //     //   'focusEd',
      //     //   true,
      //     // );
      //   }
      // }, 100);
    } else if (state.edit_mode === 'add_relationships') {
      const node = event.item;
      const point = {
        x: event.x,
        y: event.y,
      };
      const model = node.getModel();
      if (state.addingEdge && state.edge) {
        // GraphEngine(graphName, {item: sta})
        // graphReactor.exploreInstance.updateItem(state.edge, {
        //   target: model.id,
        //   label: rootState.newEdgeLabel,
        // });
        GraphEngine.setEdgeValue(
          graphName,
          {
            item: state.edge,
            value: {
              target: model.id,
              label: rootState.newEdgeLabel,
            },
          },
          '-update',
        );
        console.log('xvf', model);
        commit('SET_EDGE_VAL', null);
        commit('IS_ADDING_EDGE', false);
      } else {
        const newEdge = GraphEngine.addEdge(graphName, {
          source: model.id,
          target: point,
          label: rootState.newEdgeLabel,
          ...(state.isArrowEdge && {
            style: {
              endArrow: true,
            },
          }),
        });
        commit('SET_EDGE_VAL', newEdge);
        commit('IS_ADDING_EDGE', true);
      }
    } else if (state.edit_mode === 'hsl') {
      await dispatch('clearStates');
      // GraphEngine.setVertexValue(graphName, [{
      //   item: 'Software',
      //   state:
      // }, {
      //   item: 'Mocha',
      // }, {
      //   item: 'Gmail',
      // }], '-s-cs');
      nodeHsl(nodeModel, 'explore');
      dispatch(
        'setCurrentThought',
        {
          thoughtObject: {
            word: nodeModel.label,
            label: nodeModel.label,
            type: 'string',
            lang: 'en',
          },
          excludeCallList: ['gmodule/exploreGraphFetch'],
        },
        { root: true },
      );
    } else if (state.edit_mode === 'function_call') {
      if (nodeModel.tjType === 'function') {
        const fnResponse = await dispatch('generativeFunction', { nodeModel });
        // nodeModel.description = {};
        // nodeModel.description.content = fnResponse;
        // nodeItem.update(nodeModel);
        console.log('xvf', 'function_call', nodeItem, nodeModel, fnResponse);
      }
      await dispatch('saveG6ExploreData');
      graphReactor.exploreInstance.updateLayout();
    }

    const operationMap = {
      looked_at: 'focused on',
      add_nodes: 'Added Node',
      add_relationships: 'Added RelationShip',
      remove: 'Removed',
      hsl: 'Highlighted',
      combo_explore: 'Fetch thoughtmap',
      combo_expand_collapse: 'Expand/Collapse thoughtmap',
      hide_related_nodes: 'Hide Related Nodes',
      show_related_nodes: 'Select nodes to hide',
      hide: 'Hide',
      lock: 'Locked',
      shortest_path: 'Shortest Path',
      function_call: 'Trigger Function',
      clear_graph: 'Clear canvas',
      explore: 'Explored',
    };
    const editMode = state.edit_mode;
    // eslint-disable-next-line no-prototype-builtins
    if (editMode && operationMap.hasOwnProperty(editMode)) {
      dispatch(
        'eventToEventStore',
        {
          verb: 'GRAPH_INTERACTION',
          value: {
            type: operationMap[editMode],
            nodeDetails: nodeModel.id,
            currentGraph: rootState.active_graph_id.explore_library,
          },
        },
        { root: true },
      );
    } else {
      console.error(`Invalid or missing edit_mode: ${editMode}`);
    }
  },

  /**
   * @fn DescribeWithNodeFunction
   * @params
   *  payload: {
   *    nodeModel: Object
   *  }
   */
  /**
   * @params
   *  payload: {
   *    prompt:String,
   *    context:String,
   *    node_info_section:Object: {
   *      video: false,
   *      questions: false,
   *      article: false,
   *    },
   *    prompt_id:String
   *  }
   */
  async generativeFunction({ state, rootState, dispatch }, payload) {
    if (state.generativeFunction.isActive) {
      return null;
    }

    // Toggle the generative function state to active
    await dispatch('toggleGenerativeFunctionState', { value: true });

    const fn = payload.nodeModel.function;
    const prompt = fn.prompt || payload.nodeModel.label;
    const context = rootState.gmodule.anchor;

    const preparedPayload = {
      prompt,
      context,
      node_info_section: {
        video: false,
        questions: false,
        article: false,
      },
      prompt_id: fn.value ? fn.value[fn.variable] : '6548cb987a0bff2f08321feb',
    };

    const allRelatedQuestionsToPrompt = await dispatch(
      'quests/streamInformationFromGenAI',
      preparedPayload,
      { root: true },
    );

    const ArrayOfQuestionOfGivenPrompt = JSON.parse(allRelatedQuestionsToPrompt);
    const numberOfIterationsForQuestions = fn.numberOfQuestions || 3;

    // Process questions for logical data
    await Promise.all(
      ArrayOfQuestionOfGivenPrompt.questions
        .slice(0, numberOfIterationsForQuestions)
        .map((question) => dispatch('addLogicalData', {
          query: question,
          mode: 'default',
          selectedNodes: [],
          props: {
            isIncluded: true,
            list: ['instance-of', 'usage'],
          },
        })),
    );

    // Process selected nodes for each question
    await Promise.all(
      ArrayOfQuestionOfGivenPrompt.questions.map((question) => {
        const selectedNodes = graphReactor.exploreInstance.findAll('node', (node) => {
          const nodeModel = node.get('model');
          return nodeModel.tjType === 'openai_logic_node' && nodeModel.logicQuestion === question ? node : null;
        });

        dispatch('groupedPropertiesWithOpenAI', {
          selectedNodes,
          properties: fn.additionalProperties,
        });

        dispatch('generateDescriptionForGivenNodes', { selectedNodes });
        graphReactor.exploreInstance.updateLayout();

        return question;
      }),
    );

    // Reset the generative function state and set the edit mode
    await dispatch('toggleGenerativeFunctionState', { value: false });
    await dispatch('setEditMode', 'looked_at');

    return ArrayOfQuestionOfGivenPrompt;
  },

  /**
   * @fn generateDescriptionForGivenNodes
   * @params
   *  selectedNodes: Array []
   */
  async generateDescriptionForGivenNodes({ dispatch, rootState }, { selectedNodes }) {
    selectedNodes.forEach(async (node) => {
      const nodeModel = node.get('model');
      const prompt = nodeModel.label;
      const summaryGenerationPayload = {
        prompt,
        context: rootState.gmodule.anchor,
        node_info_section: {
          video: false,
          questions: false,
          article: false,
        },
        prompt_id: '654b1bbf7a0bff2f0832240d',
      };
      const quickSummary = await dispatch(
        'quests/streamInformationFromGenAI',
        summaryGenerationPayload,
        { root: true },
      );
      nodeModel.additionalDescription = `${quickSummary}`;
      console.log(
        'xvf',
        'generating description for',
        nodeModel.label,
        quickSummary,
        node,
        nodeModel,
      );
    });
  },

  /**
   * @fn toggleGenerativeFunctionState
   * @params
   *  value: Boolean true/false
   */
  toggleGenerativeFunctionState({ commit }, payload) {
    commit('TOGGLE_GENERATIVE_FUNCTION', payload);
  },

  /**
   * @fn GraphMouseMove
   * @params
   *---------------------------------------- | -------------------------------------------|
   * dispathcher functions,
   * graphName: name of the Graph Instance sample: 'explore'
   * event: mostly contains coordinates of the cursor in the Graph.
   */
  async graphMouseMove({ state }, { graphName, event }) {
    if (state.edit_mode === 'add_relationships') {
      const point = {
        x: event.x,
        y: event.y,
      };
      if (state.addingEdge && state.edge) {
        GraphEngine.setEdgeValue(
          graphName,
          {
            item: state.edge,
            value: {
              target: point,
            },
          },
          '-update',
        );
      }
    }
  },

  async graphEdgeClick({ state, commit }, e) {
    if (state.edit_mode === 'add_relationships') {
      const currentEdge = e.item;
      // 拖拽过程中，点击会点击到新增的边上
      if (state.addingEdge && state.edge === currentEdge) {
        // graphReactor.exploreInstance.removeItem(state.edge);
        GraphEngine.removeVertex('explore', state.edge);
        commit('SET_EDGE_VAL', null);
        commit('IS_ADDING_EDGE', false);
      }
    } else if (state.edit_mode === 'remove') {
      const currentEdge = e.item;
      // 拖拽过程中，点击会点击到新增的边上
      GraphEngine.removeVertex('explore', currentEdge);
      exploreOps.graphUpdateLayout('explore');
      // graphReactor.exploreInstance.removeItem(currentEdge);
      // graphReactor.exploreInstance.updateLayout();
    }
  },

  async graphCanvasClick({
    rootState, state, dispatch, commit,
  }, event) {
    // commit('IS_UPDATING_NODE', false);
    // this.nodeUpdating = false;
    dispatch('clearStates');

    if (state.edit_mode === 'looked_at') {
      if (state.groupMode) {
        dispatch('toggleGroupMode', false);
      }
    } else if (state.edit_mode === 'clear_graph') {
      commit('EMPTY_GRAPH');
    } else if (state.edit_mode === 'add_nodes') {
      // console.log({ newNodeLabel: rootState.newNodeLabel });
      exploreOps.addNodeGraph(
        {
          pos: {
            x: event.x,
            y: event.y,
          },
          tjType: rootState.newNodeType,
        },
        rootState.newNodeLabel,
      );
    } else {
      dispatch('clearStates', false);
    }
  },

  async setEditMode({ commit }, payload) {
    commit('SET_EDIT_MODE', payload);
  },

  async addNoteToGraph({ dispatch, rootGetters }, item) {
    await exploreOps.addNoteToGraph(item, rootGetters['auth/authUser']);
    await dispatch(
      'eventToEventStore',
      {
        verb: 'ADD_NOTE_TO_GRAPH',
        value: {
          note: item.value.note,
          noteTitle: item.value.noteTitle,
          noteUrl: item.value.noteUrl,
        },
        dataSource: 'tj-client',
      },
      {
        root: true,
      },
    );
    dispatch('saveG6ExploreData');
  },

  async addYoutubeToGraph({ dispatch, rootGetters }, item) {
    const val = {
      id: item.id,
      value: {
        noteUrl: item.embed,
        videoLabel: `${item.title.slice(0, 20)}...`,
        type: 'video',
      },
    };
    await exploreOps.addNoteToGraph(val, rootGetters['auth/authUser']);
    await dispatch(
      'eventToEventStore',
      {
        verb: 'VIDEO_ADD_TO_GRAPH',
        value: {
          link: item.embed,
          title: item.title,
        },
        dataSource: 'Youtube',
      },
      {
        root: true,
      },
    );
    dispatch('saveG6ExploreData');
  },

  async addSearchToGraph({ dispatch, rootGetters }, item) {
    let val;
    if (item.type === 'page') {
      val = {
        id: item.id,
        value: {
          label: item.label,
          noteUrl: item.noteUrl,
          type: item.type,
        },
      };
    } else {
      val = {
        id: item.id,
        value: {
          label: item.imageAlt,
          imageNote: item.note,
          noteUrl: item.noteUrl,
          type: item.type,
        },
      };
    }
    await exploreOps.addNoteToGraph(val, rootGetters['auth/authUser']);
    dispatch('saveG6ExploreData');
  },

  async cloneGraph({ commit, dispatch }, payload) {
    try {
      const { graphId } = payload;
      console.log('xvf', 'graphId', graphId);
      const response = await axios.post(`${process.env.VUE_APP_AXIOS_URI}/g6graph/clone`, {
        graphId,
      });
      if (response.status === 200) {
        dispatch('actionFeedback', { verb: 'SET_SAVED_GRAPH' }, { root: true });
        commit('SET_SAVED_GRAPH', response.data.graph, { root: true });
        dispatch(
          'fetchAllGraph',
          {
            user: '@all',
            type: 'page',
            pageNo: 0,
          },
          { root: true },
        );
        return response.data.graph;
      }
      return null;
    } catch (error) {
      return null;
    }
  },

  async toggleArrow({ commit }) {
    commit('TOGGLE_ARROW_KEY');
  },

  setVrGraph({ commit }, payload) {
    commit('SET_VR_GRAPH', payload);
  },

  setVrScale({ commit }, payload) {
    commit('SET_VR_SCALE', payload);
  },
  clearGraphID({ commit }) {
    commit('EMPTY_GRAPH_ID', 'explore_library', { root: true });
  },
  setGraphData({ commit }, data) {
    console.log('xvf -updatedGraphData', data);
    commit('SET_GRAPH_SAVED_DATA', data);
  },

  async smartGraphResponse({ state }, { payload, imageInput, callback }) {
    console.log(payload);
    try {
      const formData = new FormData();

      // Append the image file to formData. Replace `imageFile` with the actual file object.
      if (imageInput) {
        formData.append('openai_file_upload', imageInput);
      }

      // Append other parts of the data as individual form fields
      formData.append('graphQueryPrompt', payload);
      formData.append('graphId', state.graph_detail.graphId);
      const response = await fetch(`${process.env.VUE_APP_AXIOS_URI}/g6graph/smart/run`, {
        method: 'POST',
        headers: {
          // 'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
        body: formData,
      });

      if (!response.body) {
        throw new Error('ReadableStream not yet supported in this browser.');
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');

      let result = '';
      const isRun = true;
      while (isRun) {
        // eslint-disable-next-line no-await-in-loop
        const { done, value } = await reader.read();
        if (done) break;
        result += decoder.decode(value, { stream: true });
        callback(decoder.decode(value, { stream: true }));
      }

      console.log(result);
      console.log('Stream ended.');
      return result;
    } catch (error) {
      console.error('Error:', error);
    }

    return '';
  },

  // eslint-disable-next-line no-unused-vars
  async smartGraphThreadMessages({ state }, payload) {
    const response = await fetch(`${process.env.VUE_APP_AXIOS_URI}/g6graph/smart/messages`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('userToken')}`,
      },
      body: JSON.stringify({
        graphId: state.graph_detail.graphId,
      }),
    });
    return response.json();
  },

  async convertItIntoSmartGraph({ dispatch }, graph) {
    const response = await fetch(`${process.env.VUE_APP_AXIOS_URI}/g6graph/smart/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('userToken')}`,
      },
      body: JSON.stringify({
        graphId: graph._id,
      }),
    });

    const { graphMemoryObject } = await response.json();
    await dispatch(
      'updateGraphLibraryProperty',
      {
        id: graph._id,
        payload: {
          memory_id: graphMemoryObject,
        },
      },
      { root: true },
    );

    const graphObj = {
      graphId: graph._id,
      memory_id: graphMemoryObject,
      label: graph.g6_data.graph_name,
      type: 'graph',
      ...(graph.metadata && { metadata: graph.metadata }),
      questLibrary: this.libraryModelLocal,
      ...graph,
    };
    dispatch('setCurrentThought', { thoughtObject: graphObj }, { root: true });
    // return response;
  },

  async resetThread({ dispatch }, graph) {
    const response = await fetch(`${process.env.VUE_APP_AXIOS_URI}/g6graph/smart/resetThread`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('userToken')}`,
      },
      body: JSON.stringify({
        graphId: graph._id,
      }),
    });

    const { graphMemoryObject } = await response.json();
    await dispatch(
      'updateGraphLibraryProperty',
      {
        id: graph._id,
        payload: {
          memory_id: graphMemoryObject,
        },
      },
      { root: true },
    );

    const graphObj = {
      graphId: graph._id,
      memory_id: graphMemoryObject,
      label: graph.g6_data.graph_name,
      type: 'graph',
      ...(graph.metadata && { metadata: graph.metadata }),
      questLibrary: this.libraryModelLocal,
    };
    dispatch('setCurrentThought', { thoughtObject: graphObj }, { root: true });
  },
};
